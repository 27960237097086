/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
@font-face {
    font-family: 'Quicksand';
    src: url('./assets/fonts/Quicksand-VariableFont_wght.ttf');
    font-weight: 500;
    font-style: normal;
  }
@font-face {
    font-family: 'Public Sans';
    src: url('./assets/fonts/PublicSans-Italic-VariableFont_wght.ttf');
    font-weight: 200;
    font-style: normal;
  }
@font-face {
    font-family: 'Poppins';
      src: url('./assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/Raleway-VariableFont_wght.ttf');
    font-weight: 400;
    font-style: normal;
}

*:focus{
  outline: none !important;
}

h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    padding: 0;
}

body{
    background: white;
}

.modal-note{
    padding-top: 119% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}

.modal-frequence{
    .modal-wrapper{
        height: 350px;
        position: absolute;
        bottom: 0;
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}

.modal-detail-intervacation {
    padding-top: 100% !important;overflow: scroll;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;

        .content{
            overflow: scroll !important;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .modal-note{
        padding-top: 75% !important;
    }
}

@media only screen and (max-width: 800px) {
    .modal-note{
        padding-top: 110% !important;
    }
}

@media only screen and (max-width: 620px) {
    .modal-note{
        padding-top: 75% !important;
    }
}


@media only screen and (max-height: 1200px) {
    .modal-note{
        padding-top: 55% !important;
    }
}

@media only screen and (max-height: 840px) {
    .modal-note{
        padding-top: 80% !important;
    }
    @media only screen and (max-width: 1024px) {
        .modal-note{
            padding-top: 65% !important;
        }
    }
    @media only screen and (max-width: 520px) {
        .modal-note{
            padding-top: 100% !important;
        }
    }

}

@media only screen and (max-height: 740px) {
    .modal-note{
        padding-top: 70% !important;
    }
    @media only screen and (max-width: 1024px) {
        .modal-note{
            padding-top: 50% !important;
        }
    }

    @media only screen and (max-width: 430px) {
        .modal-note{
            padding-top: 83% !important;
        }
    }

    @media only screen and (max-width: 300px) {
        .modal-note{
            padding-top: 98% !important;
        }
    }

}

@media only screen and (max-height: 640px) {
    .modal-note{
        padding-top: 65% !important;
    }

    @media only screen and (max-width: 1024px) {
        .modal-note{
            padding-top: 35% !important;
        }
    }

    @media only screen and (max-width: 430px) {
        .modal-note{
            padding-top: 70% !important;
        }
    }

    @media only screen and (max-width: 330px) {
        .modal-note{
            padding-top: 60% !important;
        }
    }
}





.modal-note0{
    padding-top: 100% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 1400px) {
    .modal-note0{
        padding-top: 74% !important;
    }
}

@media only screen and (max-height: 1200px) {
    .modal-note0{
        padding-top: 50% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-note0{
        padding-top: 60% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-note0{
        padding-top: 50% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-note0{
        padding-top: 45% !important;
    }
}

ion-progress-bar{
    --background: #E5E5E5;
}
.modal-contrat{
   width: 100% !important;
   height: 100% !important;
   .modal-wrapper{
        width: 100% !important;
        height: 100% !important;
   }
}

.modal-add-date{
    
    padding-top: 79% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 1400px) {
    .modal-add-date{
        padding-top: 75% !important;
    }
}

@media only screen and (max-height: 1200px) {
    .modal-add-date{
        padding-top: 55% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-add-date{
        padding-top: 40% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-add-date{
        padding-top: 30% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-add-date{
        padding-top: 25% !important;
    }
}


@media only screen and (min-width: 1200px) {
    .modal-add-date{
        padding-top: 0% !important;
    }
}

.modal-signature{
    // padding-top: 20% !important;
    // background: none !important;
    // .modal-wrapper{
    //     border-top-left-radius: 30px !important;
    //     border-top-right-radius: 30px !important;
    // }
}


// @media only screen and (max-height: 800px) {
//     .modal-signature{
//         padding-top: 20% !important;
//     }
// }

@media only screen and (max-height: 800px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-signature{
        padding-top: 15% !important;
    }
}



.modal-delete{
    padding-top: 137% !important;
    .modal-wrapper{
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
    }
}


@media only screen and (max-height: 1400px) {
    .modal-delete{
        padding-top: 75% !important;
    }
}

@media only screen and (max-height: 1200px) {
    .modal-delete{
        padding-top: 55% !important;
    }
}

@media only screen and (max-height: 800px) {
    .modal-delete{
        padding-top: 98% !important;
    }
}

@media only screen and (max-height: 740px) {
    .modal-delete{
        padding-top: 88% !important;
    }
}

@media only screen and (max-height: 640px) {
    .modal-delete{
        padding-top: 83% !important;
    }

    @media only screen and (max-width: 330px) {
        .modal-delete{
            padding-top: 60% !important;
        }
    }
}

.w-toast{
    --width: 100% !important;
    --height: 40px !important;
    --color: #FFFFFF !important;
    --border-radius: none !important;
    --background: #C84F94 !important;
    --border: none !important;
    --font-family: Poppins;
    --font-style: normal;
    --font-weight: 500;
    --font-size: 22px;
}


.w-selected-day{
    border: 1px solid #C84F94;
    border-radius: 50%;
}

.day-agenda-popover{
    .popover-content{ 

        // min-width: 320px; 
        // min-width: 375px;
        min-width: 96%;

    }
}

.delete-account-popover{
    // top: 650px;
    .popover-content{ 
        // min-width: 320px; 
        // min-width: 375px;
        min-width: 96%;
        top: 287px!important;

    }
}


app-hide-modalx, app-hide-modal{
    z-index: 10000;
}

ion-app.cameraView, ion-app.cameraView ion-content, ion-app.cameraView .nav-decor, ion-app.cameraView ion-modal {
    background: transparent none !important;
    --background: transparent none !important;
}

ion-app.cameraView ion-content, ion-app.cameraView .nav-decor, ion-app.cameraView ion-modal {
    visibility: hidden;
}

body.cameraViewBody{
    background: transparent none !important;
    --background: transparent none !important;
}

ion-app.cameraView {
    background-size: 100% 100% !important;
    /* To show image border */
    background-image: url('./assets/imgs/qr-preview.png') !important;
}

.err-form{
  text-align: center;
  margin-top: 0;
  color: rgb(199, 24, 24);
  background: white;
}


.w-alert{
  --background: white;
  --color: #000;
  color: #000;
  .alert-radio-label{
    color: #000;
  }
}

ion-select.custom-color {
  /* Applies to the value and placeholder color */
  color: #000;
  /* Set a different placeholder color */
  --placeholder-color: #000;
  /* Set full opacity on the placeholder */
  --placeholder-opacity: 1;

}

ion-select.custom-color::part(placeholder) {
  font-size: 16px;
}

// CUSTOM RANGER SLIDE
// ::ng-deep {
.custom-slider .ngx-slider .ngx-slider-bar {
  background: rgba(201, 80, 149, 0.32);
  height: 6px;
}

.custom-slider .ngx-slider .ngx-slider-selection {
  background: #c95095;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 20px;
  height: 20px;
  top: -7px;
  bottom: 0;
  background: #c95095;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}


.alert-wrapper.sc-ion-alert-ios{
  --background: white !important;
}
.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 15px;
}

.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  background: white;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 16px;
  margin-left: 5px;
  border-radius: 0;
  background: #c95095;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: rgba(201, 80, 149, 0.32);
}

/** FIN CUSTOM SLIDER */

.modal-errors-candidature{
    .modal-wrapper{
        position: absolute;
        bottom: 0;
        height: 500px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 19px 38px rgb(0 0 0 / 30%),
        0 15px 12px rgb(0 0 0 / 22%);
    }
}

.modal-action-rh, .modal-histo-absences{
    .ion-page{
        height: 100%;
        position: relative;
        overflow: initial;
    }
}

.fc-agenda{
    .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0 !important;
    }

    .fc .fc-daygrid-event {
      height: 30px;
      display: flex;
      align-items: center;
    }
}

.ionicon {
    color: black;
    --color: black;
}

.container-disponibilites{
    .event-overlap{
        // width: 200%;
        // margin-left: -100%;
        border-radius: 0;
        font-size: 10px;
    }
}

.fc-agenda{
    .event-indispo{
        border-color: transparent;
        background-color: rgb(255, 0, 0);
        border-radius: 0;
        font-size: 10px;
        margin-left: -3px;
        width: 108%;
    }
}

.cordova-camera-select{
    background: #dbdbdb;
    padding: 20px;
    bottom: 0;
    position: absolute !important;
    width: 100%;
    height: 130px;
    padding-top: 50px;
}

ion-checkbox{
    --border-color:	rgb(0, 0, 0) !important;
     border-color : rgb(0, 0, 0) !important;
 
   }


   ion-radio{
    --border-color:	rgb(0, 0, 0) !important;
     border-color : rgb(0, 0, 0) !important;
 
   }
   ion-radio {
    --color: #0064D9;
  }

  .text-primary{
    color:#C84F94;
  }

.alert-success {
    color: green;
    background-color: #d1f1dc;
    border-color: #beebd8;
}
.alert-danger{
    color:rgb(239, 59, 59);
    background-color: #f1d1d1;
    border-color: #ebccbe;
}